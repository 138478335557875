import axios from 'axios';
import Cookies from 'js-cookie';
import { WaWAPI } from './waw-api';
import { AuthenticationDTO, RegistrationStatusDTO, UserDetailsDTO, UserRegistration } from './types/auth';
import { DisposalReasonDTO, DisposalRecordDTO, CreateDisposalDTO, DisposalLocationDTO } from './types/disposal';
import { ProductDTO } from './types/product';

const AUTHORIZATION_HEADER = 'Authorization';

const buildAuthorizationHeader = (token: string): string => `Bearer ${token}`;

const handleResponse = <T>(result: any, f: (data: any) => T): T => {
  // As per https://stackabuse.com/handling-errors-with-axios/
  if (result.response) {
    // The client was given an error response (5xx, 4xx)
    throw new Error(result.response.statusText);
  } else {
    // This is a standard response.
    return f(result.data);
  }
};

const toUserDetails = (data: any): UserDetailsDTO => {
  return Object.assign({}, data, {
    licenseAcceptedAt: new Date(data.licenseAcceptedAt),
    createdAt: new Date(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? new Date(data.lastModifiedAt) : undefined,
  });
};

const toDisposalRecordDTO = (data: any): DisposalRecordDTO => {
  return Object.assign({}, data, {
    disposedAt: Date.parse(data.disposedAt),
    lastModifiedAt: data.lastModifiedAt ? Date.parse(data.lastModifiedAt) : undefined,
  });
};

const toProductDTO = (data: any): ProductDTO => {
  return Object.assign({}, data, {
    createdAt: Date.parse(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? Date.parse(data.lastModifiedAt) : undefined,
  });
};

const toDisposalLocationDTO = (data: any): DisposalLocationDTO => {
  return Object.assign({}, data, {
    createdAt: Date.parse(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? Date.parse(data.lastModifiedAt) : undefined,
  });
};

const toDisposalReasonDTO = (data: any): DisposalReasonDTO => {
  return Object.assign({}, data, {
    createdAt: Date.parse(data.createdAt),
    lastModifiedAt: data.lastModifiedAt ? Date.parse(data.lastModifiedAt) : undefined,
  });
}

abstract class BaseWaWAPI implements WaWAPI {
  protected baseUrl: string;

  protected constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async reauthenticate(): Promise<AuthenticationDTO> {
    const url = `${this.baseUrl}/public/reauthenticate`;
    return axios.put(url).then(response => {
      if (response.data) {
        this.setAuthToken(response.data.token);
        return response.data as AuthenticationDTO;
      } else {
        throw new Error('Invalid credentials');
      }
    });
  }

  async authenticate(username: string, password: string): Promise<AuthenticationDTO> {
    const url = `${this.baseUrl}/public/authenticate`;
    return axios.post(url, {
      username: username,
      password: password,
    }).then(response => {
      if (response.data) {
        this.setAuthToken(response.data.token);
        return response.data as AuthenticationDTO;
      } else {
        throw new Error('Invalid credentials');
      }
    });
  }

  async register(regDetails: UserRegistration): Promise<RegistrationStatusDTO> {
    const url = `${this.baseUrl}/public/register`;
    return axios.post(url, regDetails).then(res => handleResponse(res, (data: any) => {
      const regStatus = data as RegistrationStatusDTO;
      if (regStatus.authentication) {
        this.setAuthToken(regStatus.authentication.token);
      }
      return regStatus;
    }));
  }

  async isLoggedIn(): Promise<boolean> {
    const url = `${this.baseUrl}/public/authenticated`;
    return axios.get(url)
      .then(res => handleResponse(res, (data: any) => data.authenticated));
  }

  async logout(): Promise<void> {
    const url = `${this.baseUrl}/public/logout`;
    return axios.post(url).then(res => handleResponse(res, (data: any) => {
      this.clearAuthToken();
    }));
  }

  /*
   * USER MANAGEMENT
   */
  async getMyDetails(): Promise<UserDetailsDTO> {
    const url = `${this.baseUrl}/user/own`;
    return axios.get(url)
      .then(res => handleResponse(res, toUserDetails));
  }

  async getProducts(): Promise<ProductDTO[]> {
    const url = `${this.baseUrl}/masterdata/products`;
    return (axios.get(url).then(res => res.data.map(toProductDTO)));
  }

  async getDisposalLocations(): Promise<DisposalLocationDTO[]> {
    const url = `${this.baseUrl}/masterdata/locations`;
    return (axios.get(url).then(res => res.data.map(toDisposalLocationDTO)));
  }

  async getDisposalReasons(): Promise<DisposalReasonDTO[]> {
    const url = `${this.baseUrl}/masterdata/reasons`;
    return (axios.get(url).then(res => res.data.map(toDisposalReasonDTO)));
  }

  async getLatestDisposals(): Promise<DisposalRecordDTO[]> {
    const to = Date.now();
    const from = to - 8640000000; // TODO: 100 days ... should be within time since start of last working day
    const url = `${this.baseUrl}/disposals/${from}`;
    return (axios.get(url).then(res =>  res.data.map(toDisposalRecordDTO)));
  }

  async getDisposalsWithinRange(from: Date, to: Date): Promise<DisposalRecordDTO[]> {
    const url = `${this.baseUrl}/disposals/${from.getTime()}/${to.getTime()}`;
    return axios.get(url).then(res =>  res.data.map(toDisposalRecordDTO));
  }

  async getDisposalByUuid(uuid: string): Promise<DisposalRecordDTO | undefined> {
    const url = `${this.baseUrl}/disposal/${uuid}`;
    try {
      return axios.get(url).then(res => toDisposalRecordDTO(res.data));
    } catch (error) {
      console.error('Failed to fetch waste', error);
      return Promise.reject(error);
    }
  }

  async createDisposal(record: CreateDisposalDTO): Promise<DisposalRecordDTO> {
    const url = `${this.baseUrl}/disposal`;
    return axios.post(url, record).then(res =>  toDisposalRecordDTO(res.data));
  }

  async deleteDisposal(uuid: string, reason: string): Promise<void> {
    const url = `${this.baseUrl}/disposal/enddate/${uuid}`;
    try {
        return axios.post(url, {
          reason: reason
        }).then(res => {});
    } catch (error) {
      console.error('Failed to enddate record', error);
      return Promise.reject(error);
    }
  }

  protected abstract getAuthToken(): string | undefined;

  protected abstract setAuthToken(token: string): void;

  protected abstract clearAuthToken(): void;
}

class BaseWaWAPIImpl extends BaseWaWAPI {
  protected clearAuthToken(): void {
  }

  protected getAuthToken(): string | undefined {
    return undefined;
  }

  protected setAuthToken(token: string): void {
  }
}

export class BrowserWaWAPI extends BaseWaWAPI {
  constructor() {
    super(`${process.env.REACT_APP_API_URL || ''}/api`);
    const token = this.getAuthToken();
    if (token) {
      this.setAuthToken(token);
    }

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if ((typeof window !== undefined) && error && error.response) {
          if ((error.response.status === 401) && (window.location.pathname !== '/login')) {
            window.location.href = '/login';
          }
        }
        return error;
      },
    );
  }

  protected getAuthToken(): string | undefined {
    return Cookies.get('authToken');
  }

  protected setAuthToken(token: string): void {
    axios.defaults.headers.common[AUTHORIZATION_HEADER] = buildAuthorizationHeader(token);
    Cookies.set('authToken', token);
  }

  protected clearAuthToken(): void {
    delete axios.defaults.headers.common[AUTHORIZATION_HEADER];
    Cookies.remove('authToken');
  }
}

export class TestWaWAPI extends BaseWaWAPI {

  private token?: string;

  constructor() {
    super('http://localhost:8000/api');

    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(
      value => {
        if (this.token) {
          value.headers[AUTHORIZATION_HEADER] = buildAuthorizationHeader(this.token);
        }

        return value;
      },
    );
    axios.interceptors.response.use(
      (response) => response,
      (error) => error,
    );
  }

  protected getAuthToken(): string | undefined {
    return this.token;
  }

  protected setAuthToken(token: string): void {
    this.token = token;
    axios.defaults.headers.common[AUTHORIZATION_HEADER] = buildAuthorizationHeader(token);
  }

  protected clearAuthToken(): void {
    this.token = undefined;
    delete axios.defaults.headers.common[AUTHORIZATION_HEADER];
  }
}

