import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { setDisposals } from '../features/slices/disposalSlice';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { css } from '@emotion/react';
import { Route } from 'react-router-dom';
import Workspace from '../components/Workspace';
import moment from 'moment';
import _ from 'lodash';
import api from '../api';
import ConfirmDialog, { confirmDialog } from '../components/ConfirmDialog';

type ColumnDefinition = {
  name: string,
  description: string,
  lookupName?: string,
  lookupMasterdata?: string,
  toDisplay?: (value: any, params?: any) => string,
  minWidth?: number
  flex?: number,
  renderCell: (params: GridRenderCellParams) => JSX.Element
};

const rowClassName = (row: any) => {
  return row.id % 2 === 0 ? 'even' : 'odd';
};

const toString = (value: any): string => {
  return (value) ? value.toString() : '';
};

const toDate = (value: any): string => {
  return moment(value).format('DD/MM/YYYY hh:mm:ss')
};

const toProduct = (code: any, products?: any): any | undefined => {
  if (products && products[code]) {
    return products[code];
  }

  return undefined;
};

const toProductCode = (code: any, products?: any): string => code;
const toProductCategory = (code: any, products?: any): string => toProduct(code, products)?.category || 'Unknown';
const toProductName = (code: any, products?: any): string => toProduct(code, products)?.name || 'Unknown';
const toProductSize = (code: any, products?: any): string => toProduct(code, products)?.size || 'Unknown';
const toProductVariant = (code: any, products?: any): string => toProduct(code, products)?.variant || 'Unknown';

const toDisposalLocation = (code: any, locations?: any): string => {
  if (locations && locations[code]) {
    const r = locations[code];
    return `${r.name}`;
  }

  return code;
};

const toDisposalReason = (code: any, reasons?: any): string => {
  if (reasons && reasons[code]) {
    const r = reasons[code];
    return `${r.name}`;
  }

  return code;
};

const toCaptureMethod = (code: any): string => {
  switch (code) {
    case 'C':
      return 'Camera';
    case 'H':
      return 'Scanner';
    case 'M':
      return 'Manual';
    default:
      return 'Unknown';
  }
};


const dataGridStyles = css({
  '.MuiDataGrid-viewport, .MuiDataGrid-row,.MuiDataGrid-renderingZone': {
    maxHeight: 'fit-content !important',
  },
  '.MuiDataGrid-cell': {
    //maxHeight: 'fit-content !important',
    overflow: 'auto',
    alignItems: 'center',
  },
  '.dataGrid--header': {
    backgroundColor: '#000000',
    color: '#ffffff',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bolder',
  },
  '.MuiDataGrid-main': {
    height: 'calc(100vh - 150px)',
    overflow: 'scroll',
  },
});

function toObject<T>(items: T[], name: string): {[key:string]:T} {
  return _.reduce(items , (obj: {[key:string]:T}, item: T) => {
    obj[(item as any)[name]] = item;
    return obj;
  }, {});
};

const dispatchDisposals = (dispatch: AppDispatch) => {
  api.getLatestDisposals().then(async (disposals:any) => {
    dispatch(
      setDisposals({
        disposals: disposals,
      })
    );
  });
};

const deleteDisposal = (dispatch: AppDispatch, uuid: string, reason: string) => {
  api.deleteDisposal(uuid, reason).then(() => {
    dispatchDisposals(dispatch);
  });
};

const Waste = () => {
  const dispatch: AppDispatch = useDispatch();

  const wasteDisposals = useSelector((state: RootState) => state.disposal.disposals);
  const masterdata = useSelector((state: RootState) => state.masterdata);

  const renderTextCell = (params: GridRenderCellParams): JSX.Element => (
    <Box my={0.25}>
      <Typography variant="body1">
        {params.value && params.value.trim().length > 0 ? params.value : '(N/A)'}
      </Typography>
    </Box>
  );

  const renderDeleteButton = (params: GridRenderCellParams): JSX.Element => (
    <Box my={0.25}>
      <IconButton color="error" onClick={() => {
        const row = params.row;
        confirmDialog(`Please confirm deletion. This cannot be reversed.`,
          `${row.quantity}x ${row.product} from ${row.location}`,  () => {
            deleteDisposal(dispatch, row.uuid, 'End date requested');
          });
      }}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );

  const columnDefs: ColumnDefinition[] = [{
    name: 'delete_action',
    description: '',
    minWidth: 32,
    renderCell: renderDeleteButton,
  }, {
    name: 'date',
    description: 'Date / Time',
    lookupName: 'disposedAt',
    toDisplay: toDate,
    minWidth: 170,
    renderCell: renderTextCell,
  }, {
    name: 'quantity',
    description: 'Quantity',
    lookupName: 'numberDisposed',
    toDisplay: toString,
    minWidth: 70,
    renderCell: renderTextCell,
  }, {
    name: 'code',
    description: 'Code',
    lookupName: 'productCode',
    lookupMasterdata: 'products',
    toDisplay: toProductCode,
    minWidth: 70,
    renderCell: renderTextCell,
  }, {
    name: 'category',
    description: 'Category',
    lookupName: 'productCode',
    lookupMasterdata: 'products',
    toDisplay: toProductCategory,
    minWidth: 160,
    renderCell: renderTextCell,
  }, {
    name: 'product',
    description: 'Product',
    lookupName: 'productCode',
    lookupMasterdata: 'products',
    toDisplay: toProductName,
    minWidth: 350,
    renderCell: renderTextCell,
  }, {
    name: 'variant',
    description: 'Variant',
    lookupName: 'productCode',
    lookupMasterdata: 'products',
    toDisplay: toProductVariant,
    minWidth: 160,
    renderCell: renderTextCell,
  }, {
    name: 'barCode',
    description: 'Bar Code',
    lookupName: 'capturedBarCode',
    toDisplay: toString,
    minWidth: 140,
    renderCell: renderTextCell,
  }, {
    name: 'reason',
    description: 'Reason',
    lookupName: 'reasonCode',
    lookupMasterdata: 'reasons',
    toDisplay: toDisposalReason,
    minWidth: 200,
    renderCell: renderTextCell,
  }, {
    name: 'location',
    description: 'Location',
    lookupName: 'locationCode',
    lookupMasterdata: 'locations',
    toDisplay: toDisposalLocation,
    flex: 1,
    renderCell: renderTextCell,
  }];
  useEffect(() => {
    dispatchDisposals(dispatch);
  }, [dispatch]);

  
  const columns = useMemo(() => {
    return columnDefs.map((cd, index) => ({
      field: cd.name,
      headerName: cd.description,
      headerClassName: 'dataGrid--header',
      flex: cd.flex,
      width: cd.minWidth,
      renderCell: cd.renderCell,
    }));
  }, []);

  const rows = useMemo(() => {
    return Object.assign([], wasteDisposals)
      .sort((a: any, b: any) => (a.disposedAt > b.disposedAt) ? -1 : 1)
      .map((dr: any, index: number) => {
      let result: any = {
        id: index,
        uuid: dr.uuid,
      };

      columnDefs.forEach(cd => {
        const md = masterdata as any;
        if (cd.lookupName) {
          const toDisplay = cd.toDisplay || toString;
          switch (cd.lookupMasterdata) {
            case 'products':
              result[cd.name] = toDisplay(dr[cd.lookupName], md.products);
              break;

            case 'reasons':
              result[cd.name] = toDisplay(dr[cd.lookupName], md.reasons);
              break;

            case 'locations':
              result[cd.name] = toDisplay(dr[cd.lookupName], md.locations);
              break;

            default:
              result[cd.name] = toDisplay(dr[cd.lookupName]);
              break;
          }
        } else {
          result[cd.name] = '';
        }
      });

      return result;
    });
  }, [masterdata, wasteDisposals]);

  return (
    <Box height="100vh" overflow="scroll">
      <Typography display="inline" variant="h5">
        Latest Disposals
      </Typography>
      <Box p={1} my={0.75}>
        <ConfirmDialog />
        <DataGrid
          editMode="row"
          rowSelection={false}
          columns={columns}
          rows={rows}
          getRowHeight={() => 'auto'}
          disableColumnMenu={false}
          hideFooter={true}
          css={dataGridStyles}
          isCellEditable={() => false}
          getRowClassName={(params) => rowClassName(params.row)}
        />
      </Box>
    </Box>
  );
};

const DisposalsRoute = (path: string) => (
  <Route
    key={path}
    path={path}
    element={
      <Workspace>
        <Waste></Waste>
      </Workspace>
    }
  />
);

export default DisposalsRoute;
