import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DisposalRecordDTO } from '../../libs/types/disposal';

type DisposalState = {
  disposals: DisposalRecordDTO[];
  selection: {
    disposal?: DisposalRecordDTO | null;
  };
};

const initialState: DisposalState = {
  disposals: [],
  selection: {},
};


type SetDisposalsPayload = {
  disposals: DisposalRecordDTO[];
};

type SelectDisposalPayload = {
  disposal: DisposalRecordDTO;
};

const disposalSlice = createSlice({
  name: 'disposals',
  initialState,
  reducers: {
    selectDisposal: (state, action: PayloadAction<SelectDisposalPayload | undefined>) => {
      if (action.payload) {
        const { disposal } = action.payload;
        state.selection = {
          disposal
        };
      } else {
        state.selection = {};
      }
    },

    setDisposals: (state, action: PayloadAction<SetDisposalsPayload>) => {
      state.disposals = action.payload.disposals;
    }
  },
});

export default disposalSlice.reducer;
export const {
  setDisposals,
  selectDisposal,
} = disposalSlice.actions;
