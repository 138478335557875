import { createTheme, responsiveFontSizes } from '@mui/material';

const theme = responsiveFontSizes(createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 12,
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#1d1d1d'
    },
  },
}));
export default theme;
