import './App.css';
import { RootState } from './store';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Workspace from './components/Workspace';
import Login from './pages/Login';
import DisposalsRoute from './pages/Waste';

import CameraScannerRoute from './pages/CameraScanner';
import { Page } from './libs/types/common';
import { AlertProvider } from './components/AlertProvider';

const getRoute = ({ name, path }: Page) => {
  switch (name) {
    case 'dispose':
      return CameraScannerRoute(path);

    case 'history':
      return DisposalsRoute(path);
  }
  return null;
};

function App() {
  const pages = useSelector((state: RootState) => state.app.pages);

  return (
    <AlertProvider>
      <Router>
        <Routes>
          {pages.map((page) => getRoute(page))}
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Workspace />} />
        </Routes>
      </Router>
    </AlertProvider>
  );
}

export default App;
