export class BarcodeScanner {
  private timeoutHandler?;
  private inputText: string = '';
  private barcodeScanned: (code: string) => void;

  constructor(callback: (code: string) => void) {
    this.barcodeScanned = callback;
    this.keyup = this.keyup.bind(this);

    document.addEventListener('keypress', this.keyup)
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
    this.timeoutHandler = setTimeout(() => {
      this.inputText = '';
    }, 10);
  }

  close() {
    document.removeEventListener('keypress', this.keyup);
  }

  private keyup(e: any) {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.inputText += String.fromCharCode(e.keyCode);
    }

    this.timeoutHandler = setTimeout(() => {
      if (this.inputText.length <= 3) {
        this.inputText = '';
        return;
      }
      this.barcodeScanned(this.inputText);
      this.inputText = '';
    }, 10);
  }
}
