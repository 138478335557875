import * as React from 'react';
import { css } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import LoginForm from '../components/LoginForm';

const backgroundStyles = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'url("/background.jpg") no-repeat center center fixed',
  backgroundSize: 'cover',
});

// #C92D39
const loginPaneStyles = css({
  height: '100vh',
});

const Login = () => {
  return (
    <div css={backgroundStyles}>
      <Grid container display="flex" flexDirection="column" >
        <Grid
          css={loginPaneStyles}
          item
          xs={4}
        >
          <Box m={1} p={1.25}>
            <img src="/logo.svg" alt="Brownes Dairy" width="100%" height="auto" />
          </Box>

          <Box m={2.5} p={6}>
            <LoginForm />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
