import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appSlice';
import authReducer from './features/accessControl/authSlice';
import disposalReducer from './features/slices/disposalSlice';
import masterdataReducer from './features/slices/masterdataSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    disposal: disposalReducer,
    masterdata: masterdataReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
