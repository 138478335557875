import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import Workspace from '../components/Workspace';
import { useZxing } from 'react-zxing';
import { useMediaDevices } from 'react-media-devices';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import api from '../api';
import _ from 'lodash';
import {
  Alert,
  AlertTitle, Autocomplete,
  Box,
  Button,
  Divider,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { v4 } from 'uuid';
import { DisposalLocationsDTO, DisposalReasonsDTO, ProductsDTO } from '../libs/types/disposal';
import { ProductSummaryDTO } from '../libs/types/product';
import { IntegerField } from '../components/IntegerField';
import moment from 'moment/moment';
import { useAlert } from '../components/AlertProvider';
import { BarcodeScanner } from '../libs/barcode-scanner';

const CUSTOM_REASON = 'R999';  // Custom

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const videoMirrorStyle = {
  transform: 'rotateY(180deg)',
  'WebkitTransform': 'rotateY(180deg)',
  'MozTransform': 'rotateY(180deg)',
};

type Product = {
  code: string;
  name: string;
  category: string;
  brand: string;
  barCode: string;
  variant?: string;
  size?: string;
  extra?: string;
};

type ProductRecord = Product & {
  timestamp: Date;
  location: string;
  reason: string;
  customReason?: string;
  numberDisposed: number;
};

const findProductByBarcode = (products: Product[], barCode: string): Product | null => {
  for (const p of products) {
    if (p.barCode === barCode) {
      return p;
    }
  }

  return null;
};

const findDeviceIdByLabel = (devices: MediaDeviceInfo[], label: string): string => {
  for (let d of devices) {
    if (d.label === label) {
      return d.deviceId;
    }
  }
  return devices[0]?.deviceId;
};

const getDefaultLocation = (locations: DisposalLocationsDTO): string => {
  return Object.keys(locations)[0];
};

const getDefaultReason = (reasons: DisposalReasonsDTO): string => {
  return Object.keys(reasons)[0];
};

const buildProducts = (products: ProductsDTO): Product[] => {
  return Object.keys(products).map((code: string) => {
    const p: ProductSummaryDTO = products[code];
    return {
      code: code,
      name: p.name,
      category: p.category,
      brand: p.brand,
      barCode: p.barCode,
      variant: p.variant,
      size: p.size,
      extra: p.extra,
    };
  });
};

export const CameraBarcodeScanner = () => {
  // Video
  const { devices } = useMediaDevices({
    constraints: {
      video: true,
      audio: false
    }
  });

  const videoDevices = devices?.filter((d: MediaDeviceInfo) => d.kind === 'videoinput') || [];
//  const deviceId = findDeviceIdByLabel(videoDevices, 'SWS JRW Camera');
  const deviceId = findDeviceIdByLabel(videoDevices, 'Unknown');

  const masterdata = useSelector((state: RootState) => state.masterdata);
  const [history, setHistory] = useState<ProductRecord[]>([]);
  const [captureMethod, setCaptureMethod] = React.useState<string>('MANUAL');
  const [products] = React.useState<Product[]>(buildProducts(masterdata.products));
  const [product, setProduct] = useState<Product | null>(null);
  const [locationCode, setLocationCode] = React.useState<string>(getDefaultLocation(masterdata.locations));
  const [reasonCode, setReasonCode] = React.useState<string>(getDefaultReason(masterdata.reasons));
  const [customReason, setCustomReason] = React.useState<string | undefined>(undefined);
  const [duplicates, setDuplicates] = React.useState<number>(1);
  const [isAlertVisible, setAlertVisible] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>('');
  const showAlert = useAlert();

  const { ref } = useZxing({
    timeBetweenDecodingAttempts: 200,
    deviceId,
    onDecodeResult(result) {
      const p = findProductByBarcode(products, result.getText());
      if (p) {
        setProduct(p);
        setCaptureMethod('CAMERA_SCANNER');
        showAlert(`Found product ${p.category}: ${p.name} (${p.size})`, 'info')
      }
    }
  });

  useEffect(() => {
    const barcode = new BarcodeScanner((code: string) =>  {
      const p = findProductByBarcode(products, code);
      if (p) {
        setProduct(p);
        setCaptureMethod('HANDHELD_SCANNER');
        showAlert(`Found product ${p.category}: ${p.name} (${p.size})`, 'info')
      }
    });
    return () => {
      barcode.close();
    }
  }, []);

  // Validators
  const isCustomReasonRequired = (): boolean => {
    return reasonCode === CUSTOM_REASON;
  };

  const isProductProvided = (): boolean => {
    return product !== undefined;
  };

  const isCustomReasonProvided = (): boolean => {
    return (isCustomReasonRequired()) ? (customReason !== undefined && customReason.length > 0) : true;
  };

  const isFormValid = (): boolean => {
    return isProductProvided() && (!isCustomReasonRequired() || isCustomReasonProvided());
  };

  // Action handlers
  const onReasonSelected = (event: SelectChangeEvent<string>, child: ReactNode): void => {
    setReasonCode(event.target.value);
  };

  const onLocationSelected = (event: SelectChangeEvent<string>, child: ReactNode): void => {
    setLocationCode(event.target.value);
  };

  const onCustomReasonEntered = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCustomReason(event.target.value);
  };

  const onDuplicatesEntered = (value: number): void => {
    setDuplicates(value);
  };

  const onProductDisposed = async () => {
    if (product) {
      await api.createDisposal({
        uuid: v4(),
        captureMethod: captureMethod,
        productCode: product.code,
        capturedBarCode: product.barCode,
        reasonCode: reasonCode,
        customReason: customReason,
        numberDisposed: duplicates,
        locationCode: locationCode,
      }).then(() => {
        const record: ProductRecord = {
          ...product,
          location: masterdata.locations[locationCode].name,
          reason: reasonCode,
          customReason: customReason,
          numberDisposed: duplicates,
          timestamp: new Date(),

        };

        setHistory(history.concat([record]));
        setAlertMessage(`${product.category}: ${product.name} (${product.size})`)
        setAlertVisible(true);
        _.delay(() => {
          setAlertVisible(false);
          setAlertMessage('');
        }, 5000);
      });
    }
  };

  // View builders
  const buildDisposalForm = (reasons: DisposalReasonsDTO, locations: DisposalLocationsDTO): any => {
    const reasonCodes = Object.keys(reasons).map((code: string) => {
      const name = reasons[code].name;
      return (<MenuItem value={code}>{name}</MenuItem>);
    });

    const locationCodes = Object.keys(locations).map((code: string) => {
      const name = locations[code].name;
      return (<MenuItem value={code}>{name}</MenuItem>);
    });

    return (
      <FormGroup>
        <Stack spacing={2}
               divider={<Divider orientation="horizontal" flexItem />}
        >
          <Stack spacing={1}>
            <FormLabel id="product-label">Product</FormLabel>
            <Autocomplete id="product"
                          clearOnEscape={true}
                          options={products}
                          getOptionLabel={(p: Product) => p.name}
                          value={product}
                          onChange={(event: any, newValue: Product | null) => {
                            setProduct(newValue);
                            setCaptureMethod('MANUAL');
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
            />
          </Stack>
          <Stack spacing={1}>
            <FormLabel id="reasons-label">Reason for disposal</FormLabel>
            <Select
              aria-labelledby="reasons-label"
              value={reasonCode}
              name="reason-for-disposal"
              onChange={onReasonSelected}
            >
              {reasonCodes}
            </Select>
            {isCustomReasonRequired() &&
              <TextField id="customField"
                         variant="outlined"
                         placeholder="Custom message"
                         onChange={onCustomReasonEntered}
                         disabled={!isCustomReasonRequired()}
                         multiline={true}
              />
            }
          </Stack>

          <Stack spacing={1}>
            <FormLabel id="locations-label">Location where disposed</FormLabel>
            <Select
              aria-labelledby="locations-label"
              value={locationCode}
              name="location-where-disposed"
              onChange={onLocationSelected}
            >
              {locationCodes}
            </Select>
          </Stack>

          <Stack spacing={1}>
            <FormLabel id="duplicates-label">Units to be disposed</FormLabel>
            <IntegerField id="duplicates"
                          aria-labelledby="duplicates-label"
                          constraints={{ min: 1, max: Number.MAX_SAFE_INTEGER, step: 1 }}
                          onChange={onDuplicatesEntered}
            />
          </Stack>

        </Stack>

      </FormGroup>
    );
  };

  const toDate = (value: any): string => {
    return moment(value).format('DD/MM/YYYY hh:mm:ss');
  };

  const buildHistory = (history: ProductRecord[]): any => {
    const products = history.sort((a: ProductRecord, b: ProductRecord) => b.timestamp.getTime() - a.timestamp.getTime());
    if (products.length > 0) {
      return (
        <TableContainer component={Paper} variant="elevation">
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date Time</StyledTableCell>
                <StyledTableCell>Quantity</StyledTableCell>
                <StyledTableCell>Code</StyledTableCell>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Product</StyledTableCell>
                <StyledTableCell>Variant</StyledTableCell>
                <StyledTableCell>Bar Code</StyledTableCell>
                <StyledTableCell>Reason</StyledTableCell>
                <StyledTableCell>Location</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                products.map((p) => (
                  <StyledTableRow>
                    <StyledTableCell>{toDate(p.timestamp)}</StyledTableCell>
                    <StyledTableCell>{p.numberDisposed}</StyledTableCell>
                    <StyledTableCell>{p.code}</StyledTableCell>
                    <StyledTableCell>{p.category}</StyledTableCell>
                    <StyledTableCell>{p.name}</StyledTableCell>
                    <StyledTableCell>{p.variant}</StyledTableCell>
                    <StyledTableCell>{p.barCode}</StyledTableCell>
                    <StyledTableCell>{p.reason}</StyledTableCell>
                    <StyledTableCell>{p.location}</StyledTableCell>
                  </StyledTableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <Box>
      <Grid container spacing={3} columns={{xs:12, md:12}}>
        <Grid item xs={12} md={5}>
          <video style={videoMirrorStyle} width="100%" height="auto" ref={ref} />
          <Box display={(isAlertVisible) ? 'block' : 'none'}>
            <Alert severity="success">
              <AlertTitle>{alertMessage}</AlertTitle>
            </Alert>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          {buildDisposalForm(masterdata.reasons, masterdata.locations)}
          <Box pt={3} display="flex" justifyContent="flex-end">
            <Button variant={"contained"}
                    disabled={!isFormValid()}
                    onClick={onProductDisposed}>Dispose</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {buildHistory(history)}
        </Grid>
      </Grid>
    </Box>
  );
};

const CameraScannerRoute = (path: string) => (
  <Route
    key={path}
    path={path}
    element={
      <Workspace>
        <CameraBarcodeScanner></CameraBarcodeScanner>
      </Workspace>
    }
  />
);

export default CameraScannerRoute;
