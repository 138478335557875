import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserProfile = {
  uuid: string;
  firstName: string;
  lastName: string;
};

type AuthState = {
  profile?: UserProfile;
  authToken?: string;
  permissions: string[];
};

export const initialState: AuthState = {
  profile: undefined,
  authToken: undefined,
  permissions: [],
};

export const toDisplayInitials = (userProfile?: UserProfile): string => {
  if (userProfile) {
    const firstInitial = userProfile.firstName.substring(0, 1).toUpperCase();
    const lastInitial = userProfile.lastName.substring(0, 1).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  } else {
    return '?';
  }
};

export const toDisplayName = (userProfile?: UserProfile): string => {
  return (userProfile) ? `${userProfile.firstName} ${userProfile.lastName}` : '?';
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ authToken: string; profile?: UserProfile }>
    ) => {
      const { authToken, profile } = action.payload;
      state.profile = profile;
      state.authToken = authToken;

      // TODO: extract permissions from token.
    },
    clearUser: (state) => {
      state.profile = undefined;
      state.authToken = undefined;
      state.permissions = [];
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;
export default authSlice.reducer;
