import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export type ConfirmProps = {
  title: string;
  description: string;
  confirmText?: string
  cancelText?: string;
  onSubmit?: () => void;
  close: () => void;
};

const useConfirmDialogStore = create<ConfirmProps>((set) => ({
  title: '',
  description: '',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  onSubmit: undefined,
  close: () => set({ onSubmit: undefined }),
}));

const ConfirmDialog = () => {
  const { title, description, confirmText, cancelText, onSubmit, close } = useConfirmDialogStore();
  return (
      <Dialog
        open={Boolean(onSubmit)}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary"
                  variant="outlined"
                  onClick={close}
                  autoFocus>
            {cancelText}
          </Button>
          <Button color="primary"
                  variant="contained"
                  onClick={() => {
                    onSubmit && onSubmit();
                    close();
                  }}>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export const confirmDialog = (title: string, description: string, onSubmit: () => void) => {
  useConfirmDialogStore.setState({
    title,
    description,
    onSubmit,
  });
};

export default ConfirmDialog;
