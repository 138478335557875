import React, { useState } from "react";
import TextField from "@mui/material/TextField";

type InputConstraints = {
  min: number;
  max: number;
  step: number;
};

export type IntegerFieldProperties ={
  id: string
  'aria-labelledby'?: string
  errorLabel?: string;
  constraints?: InputConstraints
  onChange: (value: number) => void;
};

const defaultInputConstraints: InputConstraints = {
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  step: 1
};

export const IntegerField = (props: IntegerFieldProperties) => {
  const [value, setValue] = useState<string>("1");
  const [error, setError] = useState(false);

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    const intValue = parseInt(newValue);
    setValue(newValue);
    if (Number.isFinite(intValue)) {
        setError(false);
        props?.onChange(intValue);
        return;
    }
    setError(true);
  };

  return (
    <TextField
      id={props.id}
      type="number"
      aria-labelledby={props['aria-labelledby'] || ''}
      InputProps={{
        inputProps: props.constraints || defaultInputConstraints
      }}
      error={error}
      helperText={error ? props.errorLabel || "Only positive numbers are permitted" : ""}
      value={value}
      onChange={handleChange}
    />
  );
}
