import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DisposalLocationDTO, DisposalReasonDTO, MasterDataSummaryDTO } from '../../libs/types/disposal';
import { ProductDTO } from '../../libs/types/product';

type MasterDataState = MasterDataSummaryDTO;

const initialState: MasterDataState = {
  reasons: {},
  locations: {},
  products: {}
};

export type SetMasterDataPayload = MasterDataSummaryDTO;

const masterDataSlice = createSlice({
  name: 'masterdata',
  initialState,
  reducers: {
    setMasterData: (state, action: PayloadAction<SetMasterDataPayload>
    ) => {
      state.reasons = action.payload?.reasons || {};
      state.locations = action.payload?.locations || {};
      state.products = action.payload?.products || {};
    },
  },
});

export default masterDataSlice.reducer;
export const {
  setMasterData,
} = masterDataSlice.actions;
